import React, { ReactElement } from 'react'
import './ManagementCommittee.scss'
import { graphql } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import loadable from '@loadable/component'
const Layout = loadable(() => import('../../components/Layout/Layout'))

type ManagementCommitteePropTypes = {
  data: {
    allFact: {
      nodes: {
        content: {
          title: string
          body: string
        }[]
        id: string
        images: {
          description: string
          featured: string
          url: string
        }[]
      }[]
    }
  }
}

const ManagementCommittee = ({
  data: { allFact },
}: ManagementCommitteePropTypes): ReactElement => {
  const manage = allFact.nodes.slice(1)

  return (
    <Layout>
      <div className="management">
        <h1 className="title">Management Committee</h1>
        <div className="management-committee">
          {manage.map((committee) => {
            return (
              <div
                className="management-committee-list"
                key={committee.content[0]?.title}
              >
                <LazyLoadImage
                  src={committee.images[0]?.url}
                  alt="committeeImage"
                />
                <h1 className="name">{committee.content[0]?.title}</h1>
                <p
                  className="position"
                  dangerouslySetInnerHTML={{
                    __html: committee.content[0]?.body,
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ManagementCommittee

export const pageQuery = graphql`
  query ManagementCommitteeQuery {
    allFact {
      ...ManagementCommitteeFields
    }
  }
`
